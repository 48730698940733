import React from "react"
import { Link } from "gatsby"

import DiscoverImg1 from "../../assets/images/bigdata-analytics/discover-img1.png"
import DiscoverImg2 from "../../assets/images/bigdata-analytics/discover-img2.jpg"
import logoImage from "../../assets/images/bigdata-analytics/ShopifyLogo.png"

const DiscoverArea = () => {
  return (
    <div
      className="discover-area ptb-80"
      style={{ backgroundImage: "DiscoverImg1" }}
    >
      <div className="container ">
        <div className="row align-items-center">
          <div
            className="col-lg-3 col-md-12"
            style={{ textAlign: "-webkit-center" }}
          >
            <div className="discover-image">
              <img src={logoImage} alt="image" style={{ width: "%" }} />
              {/*    <img src={DiscoverImg2} alt="image" /> */}
            </div>
          </div>

          <div className="col-lg-9  col-md-12">
            <div className="discover-content">
              <h1>
                Custom Ecommerce Shopify Sites Developed for{" "}
                <b>Superior Performance</b>
              </h1>
              {/*  <p>
                T"Shopify is an intuitive platform. The personalisation for your
                store is on fleek. We were able to set up and start sales in no
                time."
              </p>
              <p>
                One platform with all the ecommerce and point of sale features
                you need to start, run, and grow your business.
              </p> */}

              {/*   <Link to="/about-2" className="btn btn-primary">
                Discover More
              </Link> */}
            </div>
          </div>
        </div>
      </div>

      <div className="analytics-shape1"></div>
    </div>
  )
}

export default DiscoverArea
